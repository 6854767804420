<template>
    <v-dialog
    v-model="dialog"
    persistent
    max-width="480"
    overlay-opacity="0.8"
    >
        <v-card dark class="pa-5" align="center">
            <v-card-text>
                <v-icon class="mt-5" size="40" color="yellow darken-3"> {{icon }}</v-icon>
                <p class="text-body-1 font-weight-bold mb-0 mt-3 yellow--text text--darken-3">{{ title }}</p>
                <p class="text-body-2 mt-3 mb-0">{{ description }}</p>
            </v-card-text>
            <v-card-actions align="center">
            <v-btn
                color="yellow darken-3"
                outlined
                @click="dialog = false"
                class="mx-auto px-8"
            >
                ยกเลิก
            </v-btn>
            <v-btn
                color="yellow darken-3"
                @click="confirm"
                class="mx-auto px-8"
            >
                <span class="black--text">
                    ยืนยัน
                </span>
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        id: null
    }),
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        description: {
            type: String,
            default: 'description'
        },
        icon: {
            type: String,
            default: 'mdi-alert-circle-outline'
        }
    },
    methods: {
        show(id){
            if(id || 0 === id){
                this.id = id;
            }else{
                this.id = null;
            }
            this.dialog = true;
        },
        confirm(){
            this.dialog = false;
            this.$emit('confirm', this.id);
        }
    }
};
</script>
