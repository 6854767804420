<template>
    <v-dialog
    v-model="dialog"
    persistent
    max-width="420"
    >
        <v-card dark class="pa-3">
            <v-card-text style="text-align: center;">
                <v-row>
                    <v-col>
                        <v-icon color="yellow darken-3" size="32" class="mt-2">
                            mdi-account-check-outline
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="mt-n3">
                        <p class="font-weight-bold">ยกเลิกระงับการใช้งาน</p>
                        <p>กรุณาระบุเหตุผลในการยกเลิกระงับการใช้งาน</p>
                        <v-form v-model="isValid" ref="form">
                            <v-select
                            style="font-size: 14px;"
                            :items="items"
                            label="เลือกเหตุผลในการยกเลิกระงับการใช้งาน"
                            dense
                            v-model="unSuspend.reason"
                            :rules="rules"
                            ></v-select>
                            <v-textarea
                            v-model="unSuspend.description"
                            dense
                            outlined
                            full-width
                            label="เพิ่มเติม"
                            no-resize
                            value=""
                            ></v-textarea>
                        </v-form>
                        <v-btn
                        color="red darken-1"
                        text
                        class="mr-2"
                        @click="hide"
                        >
                            ยกเลิกรายการ
                        </v-btn>
                        <v-btn
                        color="yellow darken-3"
                        text
                        @click="confirm"
                        >
                            ยืนยัน
                        </v-btn>
                    </v-col>
                </v-row>
                <v-alert
                    class="mt-1 mb-0"
                    text
                    type="error"
                    v-if="errorMessage != ''">
                    {{errorMessage}}
                </v-alert>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
    data: () => ({
        dialog: false,
        items: [
            { text: 'กลับมาใช้งานใหม่', value: '5' },
            { text: 'ระงับการใช้งานผิดพลาด', value: '6' }
        ],
        unSuspend: {
            id: null,
            reason: null,
            description: ''
        },
        rules: [
            v => !!v || 'โปรดใส่เหตุผลในการยกเลิกระงับการใช้งาน'
        ],
        isValid: false,
        errorMessage: ''
    }),
    methods: {
        show(id){
            this.unSuspend.id = id;
            this.dialog = true;
            this.isValid = false;
        },
        async confirm(){
            this.$refs.form.validate();
            if(this.isValid){  
                this.errorMessage = '';
                const loader = this.$loading.show();
                try{
                    await axios.post(process.env.VUE_APP_BASE_URL + `/users/${this.unSuspend.id}/unsuspend`, this.unSuspend);
                    this.hide();
                    this.$emit('confirm');
                }catch(error){
                    this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                }finally{
                    loader.hide();
                }
            }
        },
        hide(){
            this.unSuspend = {
                id: null,
                reason: null,
                description: ''
            };
            this.$refs.form.resetValidation();
            this.dialog = false;
            this.errorMessage = '';
        }
    },
    mounted(){

    }
};
</script>