/**
 * 
 * @param {*} channel value 1, 2, 3, other
 * @returns text value
 */
exports.channelText = (value) => {
    if ('1' == value) {
        return 'Move sameday';
    } else if ('2' == value) {
        return 'Move transit';
    } else if ('3' == value) {
        return 'Move express';
    }
    return '';
};

/**
 * using for generate select options list
 * @returns 
 */
exports.permissionOptions = () => {
    return ['1', '2', '3'].map(v => ({
        label: this.channelText(v),
        value: v
    }));
};


/**
 * ------  Example ----
 *  { name: 'ทั้งหมด', value: 0 },
    { name: 'Move sameday', value: 1 },
    { name: 'Move transit', value: 2 }
    { name: 'Move express', value: 3 },
 */
exports.filterChannels = () => {
    const filters = [];
    filters.push({ name: 'ทั้งหมด', value: 0 });
    [1, 2, 3].forEach(v => {
        filters.push({
            name: this.channelText(v), value: v
        });
    });
    return filters;
};

/**
 * Reason and text
 * reason = { reason, note }
 * 1. Suspend User: ยกเลิกการใช้งาน
 * 2. Suspend User: ไม่ใช้แล้ว
 * 3.
 * 4. Reject Job: ข้อมูลผิดพลาด
 * 5. Reject Job: อื่นๆ
 * 6. Reject Job: อนุมัติช้า
 * 7. Reject Job: ค่าขนส่งสูง
 */
exports.reasonText = (reason) => {
    if (!reason) {
        return '';
    }
    if ('1' == reason.reason) {
        return 'ยกเลิกการใช้งาน, ' + reason.note;
    } else if ('1' == reason.reason) {
        return 'ไม่ใช้แล้ว, ' + reason.note;
    } else if ('2' == reason.reason) {
        return 'ข้อมูลผิดพลาด, ' + reason.note;
    } else if ('4' == reason.reason) {
        return 'อื่นๆ, ' + reason.note;
    } else if ('5' == reason.reason) {
        return 'อนุมัติช้า, ' + reason.note;
    } else if ('6' == reason.reason) {
        return 'อนุมัติช้า, ' + reason.note;
    } else if ('7' == reason.reason) {
        return 'ค่าขนส่งสูง, ' + reason.note;
    }
};

exports.productTypeToText = (type) => {
    const listProductType = [
        { name: 'เงินสด', value: '1' },
        { name: 'ทองคำแท่ง', value: '2' },
        { name: 'ทองรูปพรรณ', value: '3' },
        { name: 'อัญมณีและเครื่องประดับ', value: '4' },
        { name: 'เอกสาร / พัสดุ', value: '5' },
        { name: 'อื่นๆ', value: '6' }
    ];
    const result = listProductType.find(l => l.value == type);
    return result ? result.name : '';
};

exports.subProductTypeToText = (p) => {
    if (2 == p.type) {
        if (1 == p.subType) {
            return '96.50%';
        } else if (2 == p.subType) {
            return '99.99%';
        } else {
            return '-';
        }
    } else if (3 == p.type) {
        if (1 == p.subType) {
            return 'เก่า 96.50%';
        } else if (2 == p.subType) {
            return 'เก่า 99.99%';
        } else if (3 == p.subType) {
            return 'ใหม่ 96.50%';
        } else if (4 == p.subType) {
            return 'ใหม่ 99.99%';
        } else {
            return '-';
        }
    } else if (4 == p.type) {
        if (1 == p.subType) {
            return 'โลหะมีค่า';
        } else if (2 == p.subType) {
            return 'เพชร';
        } else if (3 == p.subType) {
            return 'พลอย';
        } else if (4 == p.subType) {
            return 'เครื่องประดับ';
        } else {
            return '-';
        }
    } else {
        return '-';
    }
};

exports.allowDistrictNontaburee = [
    'เมืองนนทบุรี',
    'บางกรวย',
    'บางใหญ่',
    'บางบัวทอง',
    'ปากเกร็ด'
];

exports.allowPathumtanee = [
    'ตำบลรังสิต',
    'อำเภอลำลูกกา',
    'ตำบลคลองหนึ่ง',
    'ตำบลคลองสอง',
    'ตำบลคลองสาม',
    'ตำบลคลองสี่'
];

exports.allowZoneAddress = [
    'คันนายาว',
    'คลองเตย',
    'คลองสาน',
    'คลองสามวา',
    'จตุจักร',
    'จอมทอง',
    'ดอนเมือง',
    'ดุสิต',
    'ดินแดง',
    'ตลิ่งชัน',
    'ทวีวัฒนา',
    'ทุ่งครุ',
    'ธนบุรี',
    'บางกอกน้อย',
    'บางกอกใหญ่',
    'บางกะปิ',
    'บางเขน',
    'บางขุนเทียน',
    'บางคอแหลม',
    'บางแค',
    'บางซื่อ',
    'บางนา',
    'บางบอน',
    'บางพลัด',
    'บางรัก',
    'บึงกุ่ม',
    'ป้อมปราบศัตรูพ่าย',
    'ประเวศ',
    'ปทุมวัน',
    'พระนคร',
    'พระโขนง',
    'พญาไท',
    'ภาษีเจริญ',
    'มีนบุรี',
    'ยานนาวา',
    'ราชเทวี',
    'ราษฎร์บูรณะ',
    'ลาดกระบัง',
    'ลาดพร้าว',
    'วังทองหลาง',
    'วัฒนา',
    'สวนหลวง',
    'สะพานสูง',
    'สาทร',
    'สายไหม',
    'สัมพันธวงศ์',
    'หนองแขม',
    'ห้วยขวาง',
    'หลักสี่'
];

exports.checkAllowAddress = (value, channel) => {

    if (!value || channel != 1) {
        return true;
    }

    let result = false;

    if (value.includes('จังหวัดนนทบุรี')) { // allow นนทบุรี
        for (let v of this.allowDistrictNontaburee) {
            result = value.includes(`อำเภอ${v}`);
            if (result) {
                break;
            }
        }
    } else if (value.includes('จังหวัดปทุมธานี')) { // allow ปทุมธานี
        for (let v of this.allowPathumtanee) {
            result = value.includes(v);
            if (result) {
                break;
            }
        }
    } else {  // allow เขต
        for (let v of this.allowZoneAddress) {
            result = value.includes(`เขต${v}`);
            if (result) {
                break;
            }
        }
    }
    return result;
};