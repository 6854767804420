<template>
    <v-container>
        <v-breadcrumbs :items="breadcrumbs">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-card align="center" class="pa-3" v-if="errorMessage != ''">
            <label class="red--text">{{ errorMessage }}</label>
        </v-card>
        <v-card dark v-else>
            <div class="d-flex pa-3">
                <div>
                    <p class="text-h6 my-0">
                        รายละเอียดผุ้ใช้
                    </p>
                    <p class="text-body-2 my-0">
                        ดูสถานะและแก้ไขรายละเอียดผู้ใช้งาน
                    </p>
                </div>
                <div class="ml-auto">
                    <v-btn color="yellow darken-3" outlined @click="$router.push( {name: 'UsersEdit', params: { id: Number($route.params.id) }})">
                        <v-icon>
                            mdi-border-color
                        </v-icon>
                        แก้ไขรายการ
                    </v-btn>
                </div>
            </div>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col align="center"  cols="2">
                        <v-avatar color="grey" size="80">

                        </v-avatar>
                    </v-col>
                    <v-col cols="5">
                        <p class="body-1 font-weight-bold">
                            ข้อมูลผู้ใช้งาน
                        </p>
                        <p class="body-1 my-0">
                            <strong>ชื่อผู้ใช้ : </strong>{{ user.name }}
                        </p>
                        <p class="body-1 my-0">
                            <strong>รหัสพนักงาน : </strong>{{ user.id }}
                        </p>
                        <p class="body-1 my-0">
                            <strong>Role : </strong>{{ user.role }}
                        </p>
                        <p class="body-1 mt-0 mb-2">
                            <strong>สถานะการใช้งาน : </strong>
                            <v-chip
                                outlined
                                class="ma-2"
                                :class="['กำลังใช้งาน' == user.status ? 'green--text' : 'grey--text']"
                            >
                                <v-icon left :class="{ 
                                'green--text': 'กำลังใช้งาน' == user.status, 
                                'grey--text text--lighen-2': 'รอยืนยันตัวตน' == user.status,
                                'red--text': 'ระงับการใช้งาน' == user.status
                                }">
                                mdi-circle-medium
                                </v-icon>
                                {{ user.status }}
                            </v-chip>                            
                        </p>
                        <div v-if="user.role == 'staff'">
                            <p class="body-1 font-weight-bold">
                            สิทธิ์การเข้าถึงข้อมูล
                            </p>
                            <v-checkbox 
                                class="pa-0 mt-n3" 
                                dense 
                                v-for="permission in user.permissions"
                                :key="permission"
                                v-model="user.permissions"
                                :value="permission"
                                :label="getPermissionText(permission)"
                                readonly>
                            </v-checkbox>
                        </div>
                        
                    </v-col>
                    <v-col cols="5">
                        <p class="body-1 font-weight-bold">
                            &nbsp;
                        </p>
                        <p class="body-1 my-0">
                            <strong>เบอร์โทร : </strong>{{ mobileText }}
                        </p>
                        <p class="body-1 my-0">
                            <strong>อีเมล : </strong>{{ user.email }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row v-if="errorMessage == ''" class="mt-3">
            <v-col>
                <v-btn class="mr-2" color="yellow darken-3"  outlined @click="$refs.dialogConfirmDelete.show()"> 
                    ลบข้อมูลผู้ใช้งาน
                </v-btn>
                <v-btn v-if="userStatus == 'active'" class="mr-2" color="yellow darken-3" outlined @click="$refs.dialogSuspend.show($route.params.id)">
                    ระงับการใช้งาน
                </v-btn>
                <v-btn v-if="userStatus == 'suspended'" class="mr-2" color="yellow darken-3" outlined @click="$refs.dialogUnSuspend.show($route.params.id)">
                    ยกเลิกระงับการใช้งาน
                </v-btn>
                <v-btn outlined color="yellow darken-3" @click="$refs.dialogConfirmResetPassword.show($route.params.id)">
                    <v-icon
                        right
                        dark
                        class="ml-n2 mr-1"
                    >
                        mdi-refresh
                    </v-icon>
                    รีเซตพาสเวิร์ด
                </v-btn>
            </v-col>
        </v-row>
        <dialog-suspend @confirm="confirmSuspend" ref="dialogSuspend" />
        <dialog-un-suspend @confirm="confirmUnSuspend" ref="dialogUnSuspend" />
        <dialog-confirm 
            ref="dialogConfirmDelete"
            :title="'ยืนยันลบข้อมูลผู้ใช้งาน'" 
            :description="'หากคุณต้องการลบข้อมูลผู้ใช้งานกรุณากดยืนยัน'" 
            @confirm="confirmDelete" />
        <dialog-confirm 
            ref="dialogConfirmResetPassword"
            :title="'ยืนยันรีเซ็ตรหัสผ่าน'" 
            :description="'ระบบจะรีเซ็ตรหัสผ่าน และจะส่งอีเมลล์ไปให้ผู้ใช้ตั้งค่ารหัสผ่านใหม่'" 
            @confirm="confirmResetPassword" />
        <alert-error :text="errorMessage" ref="alertError"></alert-error>
        <alert-success :text="successMessage" ref="alertSuccess"></alert-success>
    </v-container>
</template>

<script>
import { getUserById, getPermissionText } from '../../models/user';
import DialogSuspend from '../../components/DialogSuspend.vue';
import DialogConfirm from '../../components/DialogConfirm.vue';
import DialogUnSuspend from '../../components/DialogUnSuspend.vue';
import AlertError from '../../components/AlertError.vue';
import AlertSuccess from '../../components/AlertSuccess.vue';
import axios from 'axios';

export default {
    data: () => ({
        breadcrumbs: [
        {
          text: 'หน้าหลัก',
          disabled: false,
          href: '/'
        },
        {
          text: 'ข้อมูลผู้ใช้งาน',
          disabled: true,
          href: '/users'
        }
      ],
      permission: 2,
      errorMessage: '',
      successMessage: '',
      user: {}
    }),
    methods: {
        getPermissionText: getPermissionText,
        async confirmSuspend(){
            await this.getUser();
        },
        async confirmUnSuspend(){
            await this.getUser();
        },
        async getUser(){
            const loader = this.$loading.show();
            const id = this.$route.params.id;
            this.errorMessage = '';
            try{
                this.user = await getUserById(id);
            }catch(error){
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
            }finally{
                loader.hide();
            }
        },
        async confirmDelete(){
            const loader = this.$loading.show();
            const id = this.$route.params.id;
            this.errorMessage = '';
            try{
                await axios.delete(process.env.VUE_APP_BASE_URL + '/users/' + id);
            }catch(error){
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
                this.$router.go(-1);
            }
        },
        async confirmResetPassword(){
            const loader = this.$loading.show();
            this.errorMessage = '';
            this.successMessage = '';
            try{
                await axios.post(process.env.VUE_APP_BASE_URL + '/forgot-password/send/' + this.user.email);
                this.successMessage = 'รีเซ็ตรหัสผ่านสำเร็จแล้ว โปรดตรวจสอบอีเมลล์';
                this.$refs.alertSuccess.show();
            }catch(error){
                this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        }
    },
    computed: {
        mobileText(){
            if(this.user.mobile){
                const mobile = this.user.mobile;
                return mobile.substring(0, 3) + '-' + mobile.substring(3, 10);
            }else{
                return '';
            }
        },
        userStatus(){
            const status = this.user?.statusCode || '_';
            return status;
        }
    },
    async mounted(){
        await this.getUser();
    },
    components: {
        DialogSuspend,
        DialogConfirm,
        AlertError,
        AlertSuccess,
        DialogUnSuspend
    }
};
</script>

<style>
    .edit-button{
        position: fixed;
        right: 0;
        top: 0;
    }
</style>